@import 'imports';
@import 'variables';

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-size: 14px;
}

body {
  margin: 0 auto;
}

ux-dialog-overlay.active {
  background-color: #000;
  opacity: 0.5;
}
ux-dialog {
  background-color: #f7f8fb;
}

$leo-light-blue: #222A55;
$leo-blue: #121A40;
$leo-dark-blue: #080830;

$nav-bar-background-color: $leo-blue;
$nav-bar-foreground-color: #c1c5ca;
$nav-bar-hover-color: #fff;

.navbar {
  background-color: $nav-bar-background-color;
  box-shadow: 0 0 7px 1px rgba(0,0,0,.2);
  margin-bottom: 0;
  border: 0;

  .navbar-brand {
    color: $nav-bar-foreground-color;
    height: 50px;
    padding: 10px;
  }
  .navbar-toggler {
    color: $nav-bar-foreground-color;
  }
  .navbar-nav .nav-link {
    color: $nav-bar-foreground-color;
    &:hover {
      color: $nav-bar-hover-color;
    }
  }

  .make-dropdown-menu-right .dropdown-menu {
    // a copy of styling from .dropdown-menu-right
    right: 0;
    left: auto;
  }
}

$tree-view-item-margin: 30px;
$tree-view-border-color: #d7d8db;
$sidebar-border-color: #d7d8db;

.tree-view {
  ul {
    list-style:none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding-left: $tree-view-item-margin;
      border-left: 1px solid $tree-view-border-color;

      .item {
        display:block;
        position: relative;
        padding-top: 3px;
        padding-bottom: 3px;


        .item-content {
          display: inline;
          color: #000;
          cursor: pointer;

          a {
            color: unset;
            text-decoration: unset;

            &.active {
              font-weight: bold;

              &::after {
                content: "";
                position: absolute;
                left: -$tree-view-item-margin;
                top: 0;
                width: 5px;
                height: 100%;
                background-color: $nav-bar-background-color;
              }
            }
          }

          &:hover {
            color: #009;
            text-decoration: none;

            &::after {
              content: "";
              position: absolute;
              left: -$tree-view-item-margin;
              top: 0;
              width: 5px;
              height: 100%;
              background-color: $nav-bar-background-color;
            }
          }
        }
      }
    }
  }

  & > ul {
    padding-left: 0px;
    padding-right: 10px;
    font-size: 1.2em;
  }
}

ul.horizontal-list {
  padding-left: 0;
}

ul.horizontal-list li {
  display: inline;
}

ul.horizontal-list li:after {
  content: " - ";
}

ul.horizontal-list li:last-child:after {
  content: ""
};

.leo-login-create-registration-container {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;

  .leo-login-register {
    padding-top: 5px;
  }
}

.leo-registration-description-container {
  text-align: center;
  font-weight: bold;

  font-size: 1.25em;

  @media (max-width: 900px) {
    display: none;
  }

  & .leo-indent-second-line {
    padding-left: 1.8em;
    text-indent: -1.8em;

    @media (max-width: 525px) {
      padding-left: 1.3em;
      text-indent: -1.3em;
    }
  }

  & .leo-registration-description {
    text-align: left;
    width: auto;
  }

  & .leo-larger-font-size {
    font-size: 1.5em;

    @media (max-width: 1000px) {
      font-size: 1.25em;
    }

    @media (max-width: 525px) {
      font-size: 1em;
    }
  }

  & .leo-smaller-font-size {
    font-size: 0.5em;
    font-weight: normal;
  }

  & .leo-extra-line-padding-left {
    padding-left: 15%;
    font-size: 1.5em;

    @media (max-width: 1000px) {
      font-size: 1.5em;
    }

    @media (max-width: 525px) {
      font-size: 1em;
    }
  }

  & .leo-checkmark-image {
    width: 1.5em; 
    height: 1.5em;

    @media (max-width: 525px) {
      width: 1em; 
      height: 1em;
    }
  }
}

.leo-registration-container {
  min-width: 30%;
  margin-top: 10em;
  border: 1px solid black;
  padding: 10px;
  float: left;


  .leo-login-row {
    margin-top: 20px;

    &.leo-login-buttons {
      padding-bottom: 30px;

      .leo-login-reset-password {
        padding-top: 5px;
        margin-left: 10px;
        float: left;
      }

      .leo-login-cancel {
        margin-left: 10px;
        float: left;
      }

      .leo-login-sign-in {
        margin-right: 10px;
        float: right;
      }

      .leo-login-resend-auth-code {
        margin-left: 10px;
        float: left;
      }
    }
  }

  .leo-login-header {
    margin-top: 0px;
  }

  @media (max-width: 1600px) {
    margin-top: 2em;
    margin-left: 25%;
    width: 50%;
    margin-bottom: 2em;
  }

  @media (max-width: 1000px) {
    margin-top: 2em;
    margin-left: 15%;
    width: 70%;
    margin-bottom: 2em;
  }

  @media (max-width: 525px) {
    margin-top: 2em;
    margin-left: 5%;
    width: 90%;
    margin-bottom: 2em;
  }
}

.leo-login-container {
  max-width: 500px;
  margin-top: 10em;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  padding: 10px;

  .leo-login-row {
    margin-top: 20px;

    &.leo-login-buttons {
      padding-bottom: 30px;

      .leo-login-reset-password {
        padding-top: 5px;
        margin-left: 10px;
        float: left;
      }

      .leo-login-cancel {
        margin-left: 10px;
        float: left;
      }

      .leo-login-sign-in {
        margin-right: 10px;
        float: right;
      }

      .leo-login-resend-auth-code {
        margin-left: 10px;
        float: left;
      }
    }
  }

  .leo-login-header {
    margin-top: 0px;
  }
}

tr {
  .leo-field-group-add-buttons-container {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: -20px;
    z-index: 100;
  }
  &:hover {
    .leo-field-group-add-buttons-container {
      visibility: visible;
    }
  }
}


ux-dialog-container {
  z-index: 2000!important;
}


.full-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: table;
  background-color: rgba(140, 140, 140, 0.5);
  z-index: 10000;
}

.full-overlay > div {
  display: table-cell;
  vertical-align: middle;
}

full-overlay > div > div {
  width: 100px;
  height: 100px;
  margin: auto;
}

.full-overlay > div > div > img {
  width: 100%;
  height: 100%;
}

.not-saved {
  border: 1px solid blue;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

#leo-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & #leo-router-view-container {
    flex: 1 0 0;

    & #leo-profile-container {
      display: flex;
      flex-direction: row;
      height: 100%;

      & #leo-profile-sidebar-container {
        @media (max-width: 100) {
          flex: unset;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 1000;
          background-color: white;
        }

        &.leo-profile-sidebar-container-visible {
          display: block!important;
          position: relative;
        }

        flex: 0 0 300px;
        border-right: 1px solid $sidebar-border-color;
        position: relative;

        & #leo-profile-sidebar-content {
          position: absolute;
          overflow: auto;
          height: 100%;
        }
      }
    }
  }
}

.help-text-container ol {
  list-style-type: upper-roman;
  & > li > ol {
    list-style-type: upper-alpha;
    & > li > ol {
      list-style-type: decimal;
    }
  }
}

.form-control-inline {
  display: inline;
  width: auto;
}

.leo-center-text {
  text-align: center;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.btn-primary {
  background-color: $leo-blue;
  border-color: $leo-dark-blue;
  &:hover {
    background-color: $leo-dark-blue;
  }
}

.faq-body {
  p {
    padding-top: 30px;
  }
  .faq-title {
    font-weight: bold;
    display: inline-block;
    width: 50px;
  }
}

.glossary-body {
  p {
    padding-top: 30px;
  }
  .glossary-title {
    font-weight: bold;
  }
}

* {
  font-family: 'Rubik', sans-serif;
}

.leo-empty-profile-wide {
  display: flex; 
  flex-direction: row; 
  width: 100%;

  & .leo-empty-profile-left {
    flex: 1 0 0; 
    background-image: url('../assets/splash-left.png'); 
    background-repeat: repeat-x;
  }

  & .leo-empty-profile-center {
    flex: 0 1 1200px;
  }

  & .leo-empty-profile-right {
    flex: 1 0 0; 
    background-image: url('../assets/splash-right.png'); 
    background-repeat: repeat-x;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.leo-empty-profile-narrow {
  display: flex; 
  flex-direction: row; 
  width: 100%;
  display: none;

  @media (max-width: 768px) {
    display: unset;
  }
}


.small-screen-warning {
  visibility: hidden;
  position: fixed;
  display:block;

  bottom: 2em;
  width: 80%;
  left: 10%;

  background-color: $leo-dark-blue;
  border-radius: 15px;

  color: white;
  text-align: center;
  line-height: 1em;

  .warning-text {
    margin: 1.5em;
    text-align: center;
  }

  @media (max-width: 525px) {
    visibility: visible;
  }

 & .permanently-hidden {
    visibility: hidden !important;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
}

.html-field-display-div {
  background-color: white;
  padding: 15px;
  cursor: pointer;

  &:hover {
    background-color: #e8f4ff;
  }
}

.modal {
  z-index: 1500;
}